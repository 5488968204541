import { Image as GridGalleryImage } from 'react-grid-gallery';

export const tourVideo = 'https://calaceite.holiday/images/video-tour.mov';

// const balconySunset = { src: 'https://calaceite.holiday/images/balcony-sunset.jpg', alt: 'balcony-sunset' };

export const upperPoolAtNight = (
  {
    src: 'https://calaceite.holiday/images/upper-pool-at-night.jpg',
    alt: 'upper-pool-at-night',
    width: 1024,
    height: 576,
  }
);

export const upperPoolAtNightCropped = (
  {
    src: 'https://calaceite.holiday/images/upper-pool-at-night-cropped.png',
    alt: 'upper-pool-at-night-cropped',
    width: 892,
    height: 565,
  }
);

// export const terraceTableView = (
//   { src: 'https://calaceite.holiday/images/terrace-table-view.jpeg', alt: 'terrace-table-view' }
// );

export const mainImage = (
  {
    src: 'https://calaceite.holiday/images/wide-view-from-terrace-table-cropped.jpg',
    alt: 'wide-view-from-terrace-table',
  }
);

export const mainImageMobile = (
  {
    src: 'https://calaceite.holiday/images/wide-view-from-terrace-table-mobile.jpg',
    alt: 'wide-view-from-terrace-table',
  }
);

export const secondaryImage = {
  src: 'https://calaceite.holiday/images/balcony-view-center.jpg',
  alt: 'balcony-view-center',
  width: 4032,
  height: 2740,
};

export const tertiaryImage = {
  src: 'https://calaceite.holiday/images/terrace-sunny-west-view.jpg',
  alt: 'terrace-sunny-west-view',
  width: 4032,
  height: 2203,
};

export const images: GridGalleryImage[] = [
  {
    src: 'https://calaceite.holiday/images/balcony-west-view-captioned.jpg',
    alt: 'balcony-west-view-captioned',
    width: 3264,
    height: 2448,
  },
  {
    src: 'https://calaceite.holiday/images/sea-view-daytime.jpg',
    alt: 'sea-view-daytime',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/sea-view.webp',
    alt: 'sea-view',
    width: 1024,
    height: 768,
  },
  // {
  //   src: 'https://calaceite.holiday/images/balcony-west-view.jpg',
  //   alt: 'balcony-west-view',
  //   width: 3264,
  //   height: 2448,
  // },
  {
    src: 'https://calaceite.holiday/images/terrace-west-view-with-table.jpg',
    alt: 'terrace-west-view-with-table',
    width: 5152,
    height: 3864,
  },
  {
    src: 'https://calaceite.holiday/images/terrace-east-view.jpg',
    alt: 'terrace-east-view',
    width: 3076,
    height: 1814,
  },
  // {
  //   src: 'https://calaceite.holiday/images/terrace-east-view-2.jpg',
  //   alt: 'terrace-east-view',
  //   width: 3149,
  //   height: 1877,
  // },
  {
    src: 'https://calaceite.holiday/images/balcony-view-night.webp',
    alt: 'balcony-view-night',
    width: 1024,
    height: 768,
  },
  // {
  //   src: 'https://calaceite.holiday/images/sunset.webp',
  //   alt: 'sunset',
  //   width: 576,
  //   height: 768,
  // },
  {
    src: 'https://calaceite.holiday/images/table-view.jpg',
    alt: 'table-view',
    width: 4032,
    height: 3024,
  },
  // {
  //   src: 'https://calaceite.holiday/images/front-balcony-view.jpg',
  //   alt: 'front-balcony-view',
  //   width: 3264,
  //   height: 2448,
  // },
  {
    src: 'https://calaceite.holiday/images/front-door.jpg',
    alt: 'front-door',
    width: 600,
    height: 800,
  },
  {
    src: 'https://calaceite.holiday/images/doorway.jpg',
    alt: 'doorway',
    width: 3885,
    height: 2908,
  },
  {
    src: 'https://calaceite.holiday/images/kitchen-table.jpg',
    alt: 'kitchen-table',
    width: 800,
    height: 600,
  },
  // {
  //   src: 'https://calaceite.holiday/images/kitchen-table-2.jpg',
  //   alt: 'kitchen-table-2',
  //   width: 720,
  //   height: 960,
  // },
  {
    src: 'https://calaceite.holiday/images/kitchen-table-3.jpg',
    alt: 'kitchen-table-3',
    width: 4032,
    height: 2817,
  },
  {
    src: 'https://calaceite.holiday/images/kitchen-table-4.jpg',
    alt: 'kitchen-table-4',
    width: 4032,
    height: 3024,
  },
  // {
  //   src: 'https://calaceite.holiday/images/kitchen.jpg',
  //   alt: 'kitchen',
  //   width: 600,
  //   height: 800,
  // },
  {
    src: 'https://calaceite.holiday/images/kitchen-view-2.jpg',
    alt: 'kitchen-view-2',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/kitchen-view.jpg',
    alt: 'kitchen-view',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/kitchen-view-3.jpg',
    alt: 'kitchen-view-3',
    width: 3801,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/nespresso.jpg',
    alt: 'nespresso',
    width: 1080,
    height: 1080,
  },
  {
    src: 'https://calaceite.holiday/images/kitchen-counter.jpg',
    alt: 'kitchen-counter',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/kitchen-2.jpg',
    alt: 'kitchen-2',
    width: 600,
    height: 800,
  },
  {
    src: 'https://calaceite.holiday/images/living-area.jpg',
    alt: 'living-area',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/living-area-2.jpg',
    alt: 'living-area-2',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/living-room.webp',
    alt: 'living-room',
    width: 1024,
    height: 768,
  },
  {
    src: 'https://calaceite.holiday/images/smart-tv.webp',
    alt: 'smart-tv',
    width: 1024,
    height: 764,
  },
  // {
  //   src: 'https://calaceite.holiday/images/living-room-2.webp',
  //   alt: 'living-room-2',
  //   width: 1024,
  //   height: 768,
  // },
  {
    src: 'https://calaceite.holiday/images/living-area-looking-out.jpg',
    alt: 'living-area-looking-out',
    width: 3786,
    height: 2815,
  },
  {
    src: 'https://calaceite.holiday/images/smart-tv-2.jpg',
    alt: 'smart-tv-2',
    width: 1296,
    height: 1124,
  },
  // {
  //   src: 'https://calaceite.holiday/images/couch.jpg',
  //   alt: 'couch',
  //   width: 3264,
  //   height: 2448,
  // },
  {
    src: 'https://calaceite.holiday/images/couch-and-table.jpg',
    alt: 'couch-and-table',
    width: 4032,
    height: 2581,
  },
  {
    src: 'https://calaceite.holiday/images/second-bathroom.jpg',
    alt: 'second-bathroom',
    width: 320,
    height: 240,
  },
  {
    src: 'https://calaceite.holiday/images/shower.jpg',
    alt: 'shower',
    width: 532,
    height: 800,
  },
  {
    src: 'https://calaceite.holiday/images/second-bedroom-2.jpg',
    alt: 'second-bedroom-2',
    width: 3024,
    height: 4032,
  },
  {
    src: 'https://calaceite.holiday/images/second-bedroom-window.webp',
    alt: 'second-bedroom-window',
    width: 1024,
    height: 768,
  },
  {
    src: 'https://calaceite.holiday/images/second-bedroom.jpg',
    alt: 'second-bedroom',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/second-bedroom-view.jpg',
    alt: 'second-bedroom-view',
    width: 3264,
    height: 2448,
  },
  {
    src: 'https://calaceite.holiday/images/second-bedroom-closet.jpg',
    alt: 'second-bedroom-closet',
    width: 600,
    height: 800,
  },
  {
    src: 'https://calaceite.holiday/images/bathroom-sinks.webp',
    alt: 'bathroom-sinks',
    width: 1024,
    height: 768,
  },
  {
    src: 'https://calaceite.holiday/images/master-bathroom-2.jpg',
    alt: 'master-bathroom-2',
    width: 275,
    height: 183,
  },
  {
    src: 'https://calaceite.holiday/images/master-bathroom.jpg',
    alt: 'master-bathroom',
    width: 800,
    height: 532,
  },
  // {
  //   src: 'https://calaceite.holiday/images/master-bathroom-2.jpg',
  //   alt: 'master-bathroom-2',
  //   width: 4032,
  //   height: 3024,
  // },
  {
    src: 'https://calaceite.holiday/images/main-bedroom.jpg',
    alt: 'main-bedroom',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/bedroom-view-outside.jpg',
    alt: 'bedroom-view-outside',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/balcony-east-view.jpg',
    alt: 'balcony-east-view',
    width: 4032,
    height: 3024,
  },
  {
    src: 'https://calaceite.holiday/images/balcony-recliners.webp',
    alt: 'balcony-recliners',
    width: 1024,
    height: 768,
  },
  // {
  //   src: 'https://calaceite.holiday/images/sea-view-daytime-2.jpg',
  //   alt: 'sea-view-daytime-2',
  //   width: 3024,
  //   height: 4032,
  // },
  {
    src: 'https://calaceite.holiday/images/beach-staircase.jpg',
    alt: 'beach-staircase',
    width: 800,
    height: 532,
  },
  {
    src: 'https://calaceite.holiday/images/pool-trees.webp',
    alt: 'pool-trees',
    width: 1440,
    height: 1920,
  },
  {
    src: 'https://calaceite.holiday/images/lower-pool-2.jpg',
    alt: 'lower-pool-2',
    width: 902,
    height: 600,
  },
  {
    src: 'https://calaceite.holiday/images/lower-pool-and-building-view.jpg',
    alt: 'lower-pool-and-building-view',
    width: 1944,
    height: 2592,
  },
  {
    src: 'https://calaceite.holiday/images/lower-pool.jpg',
    alt: 'lower-pool',
    width: 1024,
    height: 404,
  },
  {
    src: 'https://calaceite.holiday/images/pool-view-from-building.jpg',
    alt: 'pool-view-from-building',
    width: 2048,
    height: 1152,
  },
  {
    src: 'https://calaceite.holiday/images/pool-grass.jpg',
    alt: 'pool-grass',
    width: 1440,
    height: 648,
  },
  {
    src: 'https://calaceite.holiday/images/pool-stairs.jpg',
    alt: 'pool-stairs',
    width: 800,
    height: 600,
  },
  // {
  //   src: 'https://calaceite.holiday/images/lower-pool.webp',
  //   alt: 'lower-pool',
  //   width: 1024,
  //   height: 768,
  // },
  {
    src: 'https://calaceite.holiday/images/lower-pool-view-from-above.jpg',
    alt: 'lower-pool-view-from-above',
    width: 1280,
    height: 960,
  },
  upperPoolAtNight,
  {
    src: 'https://calaceite.holiday/images/gate.webp',
    alt: 'gate',
    width: 1024,
    height: 683,
  },
  {
    src: 'https://calaceite.holiday/images/community-walkway.avif',
    alt: 'community-walkway',
    width: 1024,
    height: 681,
  },
  {
    src: 'https://calaceite.holiday/images/other-building-from-below.jpg',
    alt: 'other-building-from-below',
    width: 4896,
    height: 3672,
  },
  {
    src: 'https://calaceite.holiday/images/buildings-view-from-side.jpg',
    alt: 'buildings-view-from-side',
    width: 800,
    height: 533,
  },
  {
    src: 'https://calaceite.holiday/images/community-walkway-2.jpg',
    alt: 'community-walkway-2',
    width: 3024,
    height: 4032,
  },
  {
    src: 'https://calaceite.holiday/images/beach-panoramic.png',
    alt: 'beach-panoramic',
    width: 3462,
    height: 1744,
  },

  // balconySunset,
];
